import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FaqWrapper from './FaqWrapper'

const FaqPricing = ({title, link}) => {
  const data = useStaticQuery(graphql`
    {
      faqs:allContentfulFaqCategory(filter: {title: {eq: "Pricing Page"}})
      {
        edges {
          node {
            id: contentful_id
            node_locale
            title
            faqPageVisible
            faqList {
              id: contentful_id
              title
              desc {
                desc
              }
            }
          }
        }
      }
    }
  `)
  return <FaqWrapper catLabel={false} data={data} title={title} link={link}/>
}

export default FaqPricing