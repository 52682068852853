import React from "react"
// import { Row} from 'react-sticky-table'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Row, Col, Card } from "reactstrap"
// import { ReactTypeformEmbed } from "react-typeform-embed"
// import { Link } from "gatsby"

import useTranslations from "../useTranslations"
// import { Popup } from "react-typeform-embed"
// import { UtmContext } from "../../utils/utmContext"
// import localeContext from "../../utils/localeContext"
// import { config } from "../../utils/const";


const BrilleaPricingTable = ({landingLink, filter, PricingTableDataNew}) => {

//   const { locale } = React.useContext(localeContext);
  const { global } = useTranslations();
//   const { utm } = React.useContext(UtmContext);

console.log("global", global)

    return(
        <>
        <div className={"PricingTable_wrapper PricingTable_wrapper_4 brilleaPricing"} id="features">
            <Row>
                
                <Col>
                    <div className="pricingCards">
                    <Card className="pricingCards-item shadow">
                        <div className="priceHeader">
                        <h2>Get started</h2>
                        <h3>{global?.product?.start?.label}</h3>
                        </div>

                        <ul className="priceContents">
                            <li>
                                <h4 className="title">Ad spend</h4>
                                <h6 className="value">Up to {global?.product?.start?.value} / mo</h6>
                            </li>
                            <li>
                                <h4 className="title">Where we'll advertise</h4>
                                <h6 className="value">Up to 2 networks<br/> <small>e.g. Facebook &amp; Instagram</small></h6>
                            </li>
                            <li>
                                <h4 className="title">No. of ads</h4>
                                <h6 className="value">Up to 8 ads</h6>
                            </li>
                            <li>
                                <h4 className="title">Check Ins</h4>
                                <h6 className="value">Monthly check ins</h6>
                            </li>
                            <li>
                                <h4 className="title">Testing to find new growth opportunities</h4>
                                <h6 className="value">Light touch</h6>
                            </li>
                        </ul>
                    </Card>
                    <Card className="pricingCards-item shadow">
                        <div className="priceHeader">
                        <h2>Get growing</h2>
                        <h3>{global?.product?.grow?.label}</h3>
                        </div>

                        <ul className="priceContents">
                            <li>
                                <h4 className="title">Ad spend</h4>
                                <h6 className="value">From {global?.product?.grow?.value} / mo</h6>
                            </li>
                            <li>
                                <h4 className="title">Where we'll advertise</h4>
                                <h6 className="value">Up to 4 networks<br/> <small>e.g. Facebook, Instagram, Google Search, Google Shopping</small></h6>
                            </li>
                            <li>
                                <h4 className="title">No. of ads</h4>
                                <h6 className="value">Up to 20 ads</h6>
                            </li>
                            <li>
                                <h4 className="title">Check Ins</h4>
                                <h6 className="value">Fortnightly check ins</h6>
                            </li>
                            <li>
                                <h4 className="title">Testing to find new growth opportunities</h4>
                                <h6 className="value">Standard</h6>
                            </li>
                        </ul>
                    </Card>
                    <Card className="pricingCards-item shadow largeSize">
                        <div className="priceHeader">
                        <h2>Get scaling</h2>
                        <h3>from {global?.product?.scale?.label}</h3>
                        </div>

                        <ul className="priceContents">
                            <li>
                                <h4 className="title">Ad spend</h4>
                                <h6 className="value">From {global?.product?.scale?.value} / mo</h6>
                            </li>
                            <li>
                                <h4 className="title">Where we'll advertise</h4>
                                <h6 className="value">Unlimited networks</h6>
                            </li>
                            <li>
                                <h4 className="title">No. of ads</h4>
                                <h6 className="value">Unlimited number of ads</h6>
                            </li>
                            <li>
                                <h4 className="title">Check Ins</h4>
                                <h6 className="value">Weekly check ins</h6>
                            </li>
                            <li>
                                <h4 className="title">Testing to find new growth opportunities</h4>
                                <h6 className="value">Advanced</h6>
                            </li>
                        </ul>
                    </Card>
                    </div>
                </Col>

            </Row>
        {/* <StickyTable stickyHeaderCount={1} leftStickyColumnCount={1}>
            <Row>
                <Cell className="PricingTable_wrapper__header row_header">Monthly cost</Cell>
                <Cell className="PricingTable_wrapper__header">
                    <span className="header_label">Get started</span>
                    <span className="header_value">{global?.product?.start?.label}</span>
                </Cell>
                {/* {(filter?.grow && 
                <Cell className="PricingTable_wrapper__header brillea_highlight">
                    <span className="header_label">Get growing</span>
                    <span className="header_value">{global?.product?.grow?.label}</span>
                </Cell>
                {/* )} */}
                {/* {(filter?.scale && 
                <Cell className="PricingTable_wrapper__header">
                    <span className="header_label">Start scaling</span>
                    <span className="header_value">{global?.product?.scale?.label}</span>
                </Cell>
                {/* )} 
            </Row>

            {/* Ad Spend 
            <Row className={"even"}>
                <Cell className="header"><p>Ad spend</p></Cell>
                <Cell className={"icon_wrapper"}> Up to {global?.product?.start?.value} </Cell> 
                <Cell className={"brillea_highlight icon_wrapper"}> Up to {global?.product?.grow?.value} </Cell> 
                <Cell className={"icon_wrapper"}> Up to {global?.product?.scale?.value} </Cell> 
            </Row>

            {/* Channels 
            <Row className={"even"}>
                <Cell className="header"><p>Channels</p></Cell>
                <Cell className={"icon_wrapper"}> Up to 2 channels<br/> e.g. Facebook &amp; Instagram </Cell> 
                <Cell className={"brillea_highlight icon_wrapper"}> Up to 4 channels<br/> e.g. Facebook, Instagram, Google Search, Google Shopping </Cell> 
                <Cell className={"icon_wrapper"}> Unlimited</Cell> 
            </Row>

            {/* No of ads 
            <Row className={"odd"}>
                <Cell className="header"><p>No. of ads</p></Cell>
                <Cell className={"icon_wrapper"}> Up to 8 </Cell> 
                <Cell className={"brillea_highlight icon_wrapper"}> Up to 20 </Cell> 
                <Cell className={"icon_wrapper"}> Unlimited</Cell> 
            </Row>

            {/* Check Ins 
            <Row className={"even"}>
                <Cell className="header"><p>Check Ins</p></Cell>
                <Cell className={"icon_wrapper"}> Monthly check ins </Cell> 
                <Cell className={"brillea_highlight icon_wrapper"}> Monthly check ins </Cell> 
                <Cell className={"icon_wrapper"}> Weekly check ins</Cell> 
            </Row>


            {/* Channels 
            <Row className={"odd"}>
                <Cell className="header"><p>Testing to find new growth opportunities</p></Cell>
                <Cell className={"icon_wrapper"}> Basic </Cell> 
                <Cell className={"brillea_highlight icon_wrapper"}> Moderate </Cell> 
                <Cell className={"icon_wrapper"}> Advanced</Cell> 
            </Row> */}


{/* 
            <Row className="PricingTable_wrapper__footer">
                <Cell></Cell>
                {(filter?.tool ? (<Cell></Cell>) : null)}
                {(filter?.brillea ? (<Cell className="brillea_highlight">
                  <Popup 
                      id={(locale === 'en-US' ? config?.typeform?.getstarted?.US : config?.typeform?.getstarted?.UK)}
                      size={95} 
                      hidden={{ utm }}
                      tooltip={"Get started with brillea"}
                      onReady={() => { window.dataLayer.push({ event: "openForm", CTA: "PricingTable", }) }}
                      onSubmit={() => { window.dataLayer.push({ event: "submitForm", CTA: "PricingTable", }) }}
                  >
                  <Button color="primary" className="btn-purple" >Get started</Button>
                  </Popup>
                  </Cell>) : null)}
                {(filter?.freelancer ? (<Cell></Cell>) : null)}
                {(filter?.agency ? (<Cell></Cell>) : null)}
            </Row> */}
        {/* </StickyTable> */}
    </div>

    </>
    )
}


export default BrilleaPricingTable;