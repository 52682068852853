
import React from "react"

import { Container, Row, Col, Button } from 'reactstrap'
import ROI_Calculator from "../../../static/images/2/ROI_Calculator.png"
import ROI_Calculator_us from "../../../static/images/2/ROI_Calculator_en-US.png"
import {Link} from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import localeContext from "../../utils/localeContext"
import useTranslations from "../useTranslations"

const ROIIntro = () => {
    const { global } = useTranslations();
    const { locale } = React.useContext(localeContext);
    return(
    <div className={"section section_medium section_calculator bk-purple-dark"} id="spend_calculator">
    <Container>
        <Row>
            <Col md={{ size: 6 }} lg={{ size: 5 }}>
                <h5 className="color_white pb-3 pt-2">marketing spend calculator</h5>
                <h2 className="color_white pb-2">Not sure how much to spend on your digital marketing?</h2>
                <p className="color_white pb-3">We get asked this a lot, so we’ve put together this handy tool to help you find out how much to spend depending on your business goals. Or do what most of customers do and start with {global.currency}1,000 for the first month</p>
                <Button
                color="link" className="btn-link-yellow mb-2"
                size="lg"
                to={(locale === 'en-US' ? "https://docs.google.com/spreadsheets/d/14f8Iio-NmO74zBXCH_Z1M_mOuUGSF6tq5lt9OyIMrec/copy" :"https://docs.google.com/spreadsheets/d/1lNTOmFhxQuITRV2Xs_VGZb1mPEx9ezXTCYEvbJU3Ut0/copy")}
                target="_blank"
                tag={Link}
                >
                    Calculate my spend <FontAwesomeIcon icon={faArrowCircleRight}/>
                </Button>
            </Col>
            <Col md={{ size: 6, offset: 0 }} lg={{ size: 6, offset: 1 }}>
                <img className="box-shadow" src={(locale === 'en-US' ? ROI_Calculator_us : ROI_Calculator)} alt="ROI calculator"/>
            </Col>
        </Row>
    </Container>
</div>
)
}

export default ROIIntro;