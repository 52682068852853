import React from 'react'
import FaqItem from './FaqItem'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import localeContext from '../../utils/localeContext'

const FaqWrapper = ({data, title, catLabel, link}) => {
  const {locale} = React.useContext(localeContext)
  const location = (!locale ? "en-GB" : locale)
  if(data.faqs.edges.length > 0){
  return (
    <div className={"section section_medium section_faqs"} id="faqs">
      <Container>
          {(title? (
            <Row className={"text-center"}>
                <Col>
                    <h2 className="h3">{title}</h2>
                </Col>
            </Row>
          ) : null)}
          <Row>
            <Col>
              <div className="FaqWrapper">
                {data.faqs.edges.map(({ node }, a) => {
                  if(node.node_locale === location){
                  if(catLabel){
                    return (
                      <div className="FaqWrapper_category" key={a}>
                        <h5 className="FaqWrapper_category_title">{node.title}</h5>
                        {
                        node.faqList.map((item, i) => {
                          return <FaqItem key={i} data={item}/>
                        })
                        }
                      </div>
                    )
                  } else {
                      return (
                        <div key={a}>
                          {
                          node.faqList.map((item, i) => {
                            return <FaqItem key={i} data={item}/>
                          })
                          }
                        </div>
                      )
                    }
                  } else {
                    return null;
                  }
                })}
              </div>
            </Col>
          </Row>
          {(link? (
            <Row className={"text-center mt-4"}>
                <Col>
                    <Link to={link}>
                        <Button color="link" size="lg" className="btn-link-purple">See all FAQs <FontAwesomeIcon icon={faArrowCircleRight}/></Button>
                    </Link>
                </Col>
            </Row>
          ) : null)}
      </Container>
    </div>
  )
  } else { return null }
}

export default FaqWrapper