import React, {useState} from "react"
import { Collapse } from 'reactstrap'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const FaqItem = ({ data }) => {
  const {
    title,
    desc: {desc},
  } = data

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
//isOpen
  return (
      <div className="FaqItem">
          <div className="FaqItem__title" role="presentation" onClick={toggle}>
            <h4>{title}</h4>
            <div className={(!isOpen ? "FaqItem__title_icon" : "FaqItem__title_icon FaqItem__title_icon-open")}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
          <Collapse isOpen={isOpen}>
            <div className="FaqItem__content">
                <ReactMarkdown
                    source={desc}
                    escapeHtml={false}
                />
            </div>
          </Collapse>
      </div>
  )
}
export default FaqItem