import React from "react"

import SEO from "../components/SEO"
// import { Link } from "gatsby"
import { Container, Row, Col, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import ROIIntro from "../components/ROIIntro"
// import pricing_card_illustration from "../../static/images/2/pricing/pricing_card_illustration.png"
import Faq from "../components/FAQ/Pricing"
// import useTranslations from "../components/useTranslations"
import localeContext from "../utils/localeContext"
import { Popup } from "react-typeform-embed"
import { UtmContext } from "../utils/utmContext"
import { config } from "../utils/const"
import BrilleaPricingTable from "../components/PricingTable/brilleaPricing"


const  PricingPage = () =>  {

    // const { global } = useTranslations();
    const { locale } = React.useContext(localeContext);
    const { utm } = React.useContext(UtmContext)

    // const pricingData = [
    //   {
    //     header: "Channels",
    //     benefits: [
    //       { name: "Facebook Ads" },
    //       { name: "Instagram Ads" },
    //       { name: "Google Ads - search" },
    //     ],
    //   },
    //   {
    //     header: "Creative",
    //     benefits: [
    //       { name: "Design (from uploaded assets)" },
    //       { name: "Persuasive copywriting" },
    //     ],
    //   },
    //   {
    //     header: "brillea marketing expert",
    //     benefits: [
    //       { name: "Regular checkin sessions" },
    //       { name: "Monthly campaign insight sessions" },
    //       { name: "Answer queries and questions" },
    //       { name: "Help you scale" },
    //     ],
    //   },
    //   {
    //     header: "Campaign management",
    //     benefits: [
    //       { name: "Best in class account & campaign setup" },
    //       { name: "Ads fully run and managed" },
    //       { name: "Structured for growth & " + global.optimisation },
    //     ],
    //   },
    //   {
    //     header: "Reporting",
    //     benefits: [
    //       { name: "Real time reporting" },
    //       { name: "Monthly insight reports" },
    //       { name: "Attribution methodology via Facebook & Google platforms" },
    //     ],
    //   },
    //   {
    //     header: "Testing",
    //     benefits: [
    //       { name: "Testing new target audiences" },
    //       { name: "Explore new ad creative design and copy" },
    //       { name: "Continuous " + global.optimisation + " & improvements" },
    //     ],
    //   },
    //   {
    //     header: "Growth",
    //     benefits: [
    //       { name: "Targeting & segmentation optimisation" },
    //       { name: "Creative & messaging optimisation" },
    //       { name: "Call to action optimisation" },
    //       { name: "Channel mix & spend optimisation" },
    //     ],
    //   },
    // ]

    return (
      <>
        <SEO
          title="brillea - no more expensive agencies, freelancers or perm hires"
          description="High quality digital marketing that works. For startups & small businesses on a budget. Just £699 a month all in"
        />

        {/* <div className={"sticky_pricing_nav bk-purple-dark"}>
          <ul className="list-inline">
            <li className="list-inline-item">
              <FontAwesomeIcon icon={faCheck} /> no credit card required on
              signup
            </li>
            <li className="list-inline-item">
              <FontAwesomeIcon icon={faCheck} /> cancel anytime
            </li>
            <li className="list-inline-item">
              <FontAwesomeIcon icon={faCheck} /> fixed price
            </li>
          </ul>
        </div> */}

        <div
          className={"section section_header section_header_pricing bk-purple"}
          id="pricing_table"
        >
          <Container>
            <Row>
              <Col>
                <h1 className="text-center text-white">
                  Pick your plan. Grow your business.
                </h1>
                <p className="intro text-center mb-3 text-white">
                  Whatever stage you're at, our plans provide high performance ads that do your business justice.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <BrilleaPricingTable filter={{tool:true, brillea:true, freelancer:true, agency:true}}/>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className={"section_pricing_table section section_medium mt-3"}
          id="pricing_table"
        >
          <Container>


            <Row>
              <Col>
                  <h6 className="text-center mt-5">No hidden charges. No fixed term contract.</h6>
                  <h5 className="text-center mt-5 mb-4">All packages include:</h5>
                  <div className="pricingWrapperList text-left">
                    <Row>
                      <Col md={{ size: 4, offset: 0 }} sm={{ size: 12, offset: 0 }}>
                        <ul className="tick_list mb-3">
                          <li><strong><FontAwesomeIcon icon={faCheck}/> Full ad management</strong></li>
                          <li><FontAwesomeIcon icon={faCheck}/>Dedicated marketing expert</li>
                          <li><FontAwesomeIcon icon={faCheck}/>Copywriting &amp; ad creation</li>
                        </ul>
                      </Col>
                      <Col md={{ size: 4, offset: 0 }} sm={{ size: 12, offset: 0 }}>
                        <ul className="tick_list mb-3">
                          <li><strong><FontAwesomeIcon icon={faCheck}/> Access to the brillea platform</strong></li>
                          <li><FontAwesomeIcon icon={faCheck}/> Reporting and insights</li>
                          <li><FontAwesomeIcon icon={faCheck}/> Help &amp; guidance</li>
                        </ul>
                      </Col>
                      <Col md={{ size: 4, offset: 0 }} sm={{ size: 12, offset: 0 }}>
                        <ul className="tick_list mb-3">
                          <li><strong><FontAwesomeIcon icon={faCheck}/> Tailored growth strategy</strong></li>
                          <li><FontAwesomeIcon icon={faCheck}/> Structured testing</li>
                          <li><FontAwesomeIcon icon={faCheck}/> Data driven optimisation</li>
                        </ul>
                      </Col>

                    </Row>
                    <Row className="text-center mt-3">
                      <Col>
                      <Popup 
                          id={(locale === 'en-US' ? config?.typeform?.getstarted?.US : config?.typeform?.getstarted?.UK)}
                          // id={(locale === 'en-US' ? config.typeform.getstarted.US : config.typeform.getstarted.UK )}
                          size={95} 
                          hidden={{ utm }}
                          tooltip={"Get started with brillea"}
                          onReady={() => { window.dataLayer.push({ event: "openForm", CTA: "PricingTable", }) }}
                          onSubmit={() => { window.dataLayer.push({ event: "getStartedFormComplete", CTA: "PricingTable", }) }}
                      >
                        <Button
                          color="secondary"
                          // tag={Link} to={"/contact/"}
                          size="lg"
                        >
                          Get started
                        </Button>
                      </Popup>
                      
                      </Col>
                    </Row>
                  </div>

              </Col>
            </Row>


            {/* <Row className="mt-5 pt-3" >
              <Col sm={{ size: 12, offset: 0 }} lg={{ size: 6, offset: 0 }}>
                <Card className="mb-3">
                  <CardBody>
                    <h4>Never set up Facebook or Google ads?</h4>
                    <p className="mb-0"><strong>Let brillea take care of the tech setup for you</strong>. We'll implement the Facebook Pixel and Google Conversion tracking for a one-off fixed cost.</p>
                    <Button color="link" tag={Link} to={"/help/general/facebook-and-google-setup/"}>read more</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={{ size: 12, offset: 0 }} lg={{ size: 6, offset: 0 }}>
                <Card className="mb-3">
                  <CardBody>
                    <h4>Not sure how much to spend on your digital marketing?</h4>
                    <p className="mb-0">Most of our customers start small in the first month then scale up once they're getting the results they want.</p>
                    <Button to={"#spend_calculator"} tag={Link} color="link">read more</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
          </Container>
        </div>

        {/* <div
          className={"section_pricing section_pricing_list_wrapper section section_medium grey_bk"}
          id="features"
        >
          <Container>
            {/* <Row>
              <Col>
                <div className="pricing_table mt-5">
                  <h2 className="text-center mb-5">
                    brillea includes all these essential features
                  </h2>

                  {pricingData.map(({ header, benefits }) => (
                    <div className="pricing_table__row" key={header}>
                      <div className="pricing_table__header">
                        <p>{header}</p>
                      </div>
                      <div className="pricing_table__features">
                        {benefits.map(({ name }) => (
                          <p key={name}>
                            {name}{" "}
                            <FontAwesomeIcon
                              className={"pricing_table__features_check"}
                              icon={faCheck}
                            />
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row> 

            <Row>
              <Col className={"text-center mt-5"}>
                <h3>
                  All this for just {global.brillea_1000}
                  {(locale === 'en-GB' ? (<small className="opacity-50">+VAT</small>) : null) } per month
                </h3>
                <p className="heading_font color_purple_dark">
                  cancel anytime, no hidden costs
                </p>
                <Popup 
                    id={(locale === 'en-US' ? config?.typeform?.getstarted?.US : config?.typeform?.getstarted?.UK)}
                    size={95} 
                    hidden={{ utm }}
                    tooltip={"Get started with brillea"}
                    onReady={() => { window.dataLayer.push({ event: "openForm", CTA: "PricingTable", }) }}
                    onSubmit={() => { window.dataLayer.push({ event: "getStartedFormComplete", CTA: "PricingTable", }) }}
                >
                  <Button
                    className={"mt-3"}
                    color="secondary"
                    // tag={Link} to={"/contact/"}
                  >
                    Get started
                  </Button>
                </Popup>
              </Col>
            </Row>
          </Container>
        </div> */}

        <Faq title={"Frequently asked questions"} link={"/faqs/"} />

        <ROIIntro />
        {/* <FooterEmailSignup /> */}
      </>
    )
}
export default PricingPage
